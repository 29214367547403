//# babelOptions minify=yes comments=no mangle=no debuginfo=yes

var num=new Intl.NumberFormat('de-DE')

var cnumobs=new IntersectionObserver(entries =>
{
    entries.forEach(function(e)
    {
        if (e.isIntersecting)
        {
            var t=0;
            var cprops=getComputedStyle(e.target);
            var delta=20/(parseFloat(cprops.getPropertyValue("--counting-numbers-time"))||1000);
            var ms=20;
            //var ms=parseInt(cprops.getPropertyValue("--counting-numbers-ms"))||20;
            var tim=setInterval(function(e)
            {
                var i=0;
                t=Math.min(1,t+delta);
                e.target.innerHTML=e.target._tplstr.replace(/\{#\}/g,function(a)
                {
                    var n=e.target._numtab[i++];
                    return num.format(Math.round(n.z*n.f*t)/n.f);
                }.bind(this))
                if (t===1)
                    clearInterval(tim);
            }.bind(this,e),ms);
        }
    })
})

if (!window.initset) initset = new Set();

initset.add(new Promise(async (resolve, reject) => {
    if (!window.D)
        await import("/s/SCRIPTS/domhelpers.js");
    await D.loaded;
    
    const evHandlerMap = new Map;
    
    function init(root = document)
    {
        try
        {

            // counting-numbers {
            [...root.getElementsByClassName("counting-numbers")].forEach(z => 
            {
    		    if (evHandlerMap.has(z))
    		        return;
                let evHandlerTab = [];
                evHandlerMap.set(z,evHandlerTab);
                
                let numtab=[],tplstr;
        
                tplstr=z.innerHTML.replace(/((?:\d+\.)*\d+)(,\d+)?/g,(all,a,b) =>
                {
                    numtab.push({z:parseFloat(''+a.replace(/\./g,'')+(b||'').replace(/,/,'.')),f:b?10**(b.length-1):1});
                    return '{#}';
                })
                z._numtab=numtab;
                z._tplstr=tplstr;
                cnumobs.observe(z);
                evHandlerTab.push({cancel() { cnumobs.unobserve(z); }})
            });
            //} imploding-letters, imploding-words {
            function rek(gschrapp,type)
            {
                for (let t of [...gschrapp.childNodes])
                {
                    switch (t.nodeType)
                    {
                    case 3:
                        let str=t.nodeValue;
                        let dfrag=document.createDocumentFragment();
                        let div=document.createElement("div");
                        let RE=type==='word' ? /[\w\x7f\xff]+/g : /[\w\x7f\xff]/g;
                        div.innerHTML=str.replace(RE,'<span class="expspan">$&</span>');
                        while(div.childNodes.length)
                            dfrag.append(div.firstChild);
                        /*[...str].forEach(s => {
                            if (/\S/.test(s))
                            {
                                let span=document.createElement("span");
                                span.className='expspan';
                                span.append(s);
                                dfrag.appendChild(span);
                            }
                            else
                                dfrag.append(s);
                        })*/
                        gschrapp.replaceChild(dfrag,t);
                        break;
                    case 1:
                        rek(t,type);
                        break;
                    }
                }
            }
            if(window.name!="cms_iframe")
            {
                for (let el of root.querySelectorAll(".imploding-letters,.imploding-words"))
                {
                    let cs=getComputedStyle(el);
                    let p=cs.getPropertyValue("--refelem") || '';
                    let refelem=p.trim().toLowerCase()=='self' ? el : p && document.querySelector(p);
                    if (refelem)
                        var {left:l,right:r,top:t,bottom:b}=refelem.getBoundingClientRect();
                    else
                        var l=0, t=0, r=window.innerWidth, b=window.innerHeight;
                    
                        
                    let lsp=cs.getPropertyValue("--leftspace");
                    if (!lsp)
                        l=Math.max(0,l-300)
                    else
                        l=Math.max(0,l-(parseInt(lsp)||0));
                    
                    let rsp=cs.getPropertyValue("--rightspace");
                    if (!rsp)
                        r=Math.min(window.innerWidth,r+300);
                    else
                        r=Math.min(window.innerWidth,r+(parseFloat(rsp)||0));
                        
                    let tsp=cs.getPropertyValue("--topspace");
                    if (!tsp)
                        t=Math.max(0,t-300)
                    else
                        t=Math.max(0,t-(parseInt(tsp)||0));
                    
                    let bsp=cs.getPropertyValue("--bottomspace");
                    if (!bsp)
                        b=Math.min(window.innerHeight,b+300);
                    else
                        b=Math.min(window.innerHeight,b+(parseFloat(bsp)||0));
        
                    let w=r-l;
                    let h=b-t;
                    let d=parseFloat(cs.getPropertyValue("--effekt-delay"))||2000;
                    
                    rek(el,el.classList.contains("imploding-letters")?'char':'word');
                    let allspans=el.querySelectorAll("span.expspan");
                    let delta=d / (allspans.length || 1);
                    allspans.forEach((r,i) =>
                    {
                        // neue Position berechnen und als cssvar speichern
                        let {top,left,width,height}=r.getBoundingClientRect();
                        r.style.setProperty('--xpos',Math.round(Math.random()*(w-width)-left+l)+'px');
                        r.style.setProperty('--ypos',Math.round(Math.random()*(h-height)-top+t)+'px');
                        r.style.setProperty('--opdelay',(i*delta)+'ms');
                    });
                }
                window.requestAnimationFrame?.(()=>document.documentElement.classList.add("effects-imploding-ready")) || document.documentElement.classList.add("effects-imploding-ready");
            }
            //} cms30_wheel {
            
            window.name!="cms_iframe" && root.querySelectorAll('.cms30_wheel').forEach(wheel => 
            {
    		    if (evHandlerMap.has(wheel))
    		        return;
                let evHandlerTab = [];
                evHandlerMap.set(wheel,evHandlerTab);
                
                let p_init=new Promise((resolve,reject) => 
                {
                    if (!window.Y?.cms?.verhalten)
                        resolve(wheel.className);
                    else if (wheel.classList.contains("sichtbar") || wheel.classList.contains("oberhalb") || wheel.classList.contains("unterhalb"))
                        resolve(wheel.className)
                    else
                    {
                        let onevent;
                        wheel.addEventListener("verhalten-styleclass",onevent = ev => resolve(wheel.className));
                        evHandlerTab.push({type:"verhalten-styleclass", el:wheel, fn:onevent});
                    }
                }).then(className => 
                {
                    wheel.querySelectorAll('ul').forEach(ul => 
                    {
                        let lis = ul.querySelectorAll('li');
                        let r = parseFloat(getComputedStyle(ul).getPropertyValue('--spread'));
                        let xf = parseFloat(getComputedStyle(ul).getPropertyValue('--x-factor')) ?? 1.2;
                        let yf = parseFloat(getComputedStyle(ul).getPropertyValue('--y-factor')) ?? 0.12;
                        let zf = parseFloat(getComputedStyle(ul).getPropertyValue('--z-factor')) ?? 2;
                        let step = 10;
                        let deg2rad = 2 * Math.PI / 360;
                        let intv = parseInt(getComputedStyle(ul).getPropertyValue('--intv'));
                        var ctr = 90;
                        ul.dataset.intv = setInterval(function() {
                            lis.forEach((li, i) => {
                                let x = Math.floor(Math.cos((360 / lis.length * -i + ctr) * deg2rad) * r); //  + 50
                                let y = Math.floor(Math.sin((360 / lis.length * -i + ctr) * deg2rad) * r + 50);
                //                li.style.transform = 'translateX(' + x + '%) translateY(' + y + '%)';
                //                li.style.left = 'calc((100% - var(--li-width)) * ' + (x / 100) + ')';
                //                li.style.top = y + '%';
                //                li.style.transform = 'scale(' + (y / 200 + .75) + ')';
                
                                li.style.setProperty("--y", y);
                                let tr = 'translateX(calc((-50% - var(--li-width)) * ' + (x * xf * 2 / 100) + ')) translateY(' + y * yf * 2 + 'vw) translateZ(' + (y * zf * 2 - 400) + 'px)'; // scale(2)';
                                li.style.setProperty("-webkit-transform", tr);
                                li.style.setProperty("-moz-transform", tr);
                //                li.style.cssText = "--y: " + y + '; transform: ' + tr + ';';
                                li.style.transform = tr;
                            });
                            ctr = (ctr + step) % 360;
                        }, intv);
                        evHandlerTab.push({cancel() { clearInterval(ul.dataset.intv) }})
                    });
                });
            });

            //}
        } 
        catch (err) 
        {
            console.error("Fehler bei der Initialisierung der Effekte:", err);
            reject(err);
        }
    }

    function destroy(root = document)
    {
	 	root.querySelectorAll(".counting-numbers,.cms30_wheel").forEach(function (n)
	 	{
            const evHandlerTab = evHandlerMap.get(n);
            evHandlerTab?.forEach(({type,el,fn,cancel}) => cancel ? cancel() : el.removeEventListener(type,fn));
            evHandlerMap.delete(n);
	 	});
    }

    try {init();} catch(ex) { console.error(ex) }
    
    resolve({ init, destroy });
}));

